import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/commonFiles/footer";
import Header from "../components/commonFiles/header";
import TransactionBody from "../components/transactionScreen/transactionBody";
import { useCallback, useEffect, useState } from "react";
import { ROUTES } from "../constants/routes";
import Loader from "../components/Loader";
import axios from "axios";
import ErrorComponent from "../components/commonFiles/error";

function TransactionScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [popupMessage, setPopupMessage] = useState(""); 
  const [purchaseDetails, setPurchaseDetails] = useState({
    customerName: "",
    remainingAmt: 0,
    products: [],
    transactionId: "",
    dateOfPurchase: "",
    emailAddress: "",
    phoneNumber: "",
    directBills: [],
    totalMachineAmount: 0
  });

  const purchaseOrderId = (location.state as { purchaseOrderId: string })
    ?.purchaseOrderId;

  const fetchPurchaseDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/kiosk/get-purchase/${purchaseOrderId}`
      );
      const { firstName, lastName, emailAddress, phoneNumber } =
        response.data.data.customers;
      const { remainingAmt, products, transactionId, dateOfPurchase } =
        response.data.data;
      const directBills = response.data.availableBills.bills;

      const totalMachineAmount = response.data.totalPayStationBalance;

      setPurchaseDetails({
        customerName: `${firstName} ${lastName}`,
        remainingAmt,
        products,
        transactionId,
        dateOfPurchase,
        emailAddress,
        phoneNumber,
        directBills,
        totalMachineAmount
      });
      setLoading(false);
    } catch (error: any) {
      if (error.status === 400) {
        setPopupMessage(error.response.data.message); 
        return;
      }
      console.error("Error fetching purchase details:", error);
      navigate(ROUTES.OOPS.path);
    }
  }, [purchaseOrderId, navigate]);

  useEffect(() => {
    if (!purchaseOrderId) {
      console.log("purchaseOrderId not found");
    } else {
      fetchPurchaseDetails();
    }
  }, [fetchPurchaseDetails, purchaseOrderId]);

  const onTimerFinish = useCallback(() => {
    window.location.href = ROUTES.HOMEPAGE.path;
  }, []);

  const onProcessTransaction = useCallback(
    async () => {
      try {
        navigate(ROUTES.TRANSACTIONPROCESS.path, {
          state: {
            name: purchaseDetails?.customerName,
            amount: purchaseDetails?.remainingAmt,
            purchaseOrderId,
            bills: purchaseDetails?.directBills,
            totalMachineAmount: purchaseDetails?.totalMachineAmount
          },
        });
      } catch (error) {
        console.log("Error", error);
        alert(error);
        window.location.href = ROUTES.HOMEPAGE.path;
      }
    },
    [purchaseDetails.customerName, purchaseOrderId, navigate]
  );

  return (
    <>
      <Header duration={700} startTimer onTimerFinish={onTimerFinish} />
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader variant="LARGE" />
        </div>
      ) : (
        <TransactionBody
          // name={purchaseDetails.customerName}
          // amount={purchaseDetails.remainingAmt}
          // products={purchaseDetails.products}
          // transactionId={purchaseDetails.transactionId}
          // date={purchaseDetails.dateOfPurchase}
          // email={purchaseDetails.emailAddress}
          // phone={purchaseDetails.phoneNumber.replace(/\D/g, "")}
          // purchaseOrderId={purchaseOrderId}
          // directBills={purchaseDetails.directBills}
          onProcessTransactionPromise={onProcessTransaction}
          purchaseDetails={purchaseDetails}
        />
      )}
      <Footer />

      {/* Display the popup when there's a message */}
      {popupMessage && (
        <ErrorComponent
          message={popupMessage}
          onClose={() => {
            setPopupMessage(""); 
            navigate(ROUTES.SPLASH.path); 
          }}
        />
      )}
    </>
  );
}

export default TransactionScreen;