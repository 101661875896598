import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
// import axios from "axios";
interface Props {
  // name: string;
  // amount: number;
  // products: any;
  // transactionId: string;
  // date: string;
  // email: string;
  // phone: string;
  // purchaseOrderId: string;
  // directBills: any;
  purchaseDetails:any;
  onProcessTransactionPromise: any;
}

function TransactionBody({
  // name,
  // amount,
  // products,
  // transactionId,
  // date,
  // // email,
  // // phone,
  // // purchaseOrderId,
  // // directBills,
  purchaseDetails,
  onProcessTransactionPromise,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  // const [showPayCashGiftModal, setShowPayCashGiftModal] = useState(false);
  // const [showGiftModal, setShowGiftModal] = useState(false);
  // const [payAmount, setPayAmount] = useState({
  //   payCash: amount.toFixed(2),
  //   payGift: "",
  //   totalOfferAmount: amount.toFixed(2),
  //   email: email,
  //   phone: phone,
  // });
  // const [isCheckedEmail, setIsCheckedEmail] = useState(false);
  // const [isCheckedPhone] = useState(true);
  // const [Error, setError] = useState("");
  // const [loading, setLoading] = useState(false);
  // const [inputValue, setInputValue] = useState("");

  const openDialog = useCallback(() => {
    setIsOpen(true);
    document.body.classList.add("modal-open");
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    document.body.classList.remove("modal-open");
  }, []);

  // const openPayCashGiftModal = () => {
  //   setPayAmount((prev) => ({
  //     ...prev,
  //     payCash: amount.toFixed(2),
  //     totalOfferAmount: amount.toFixed(2),
  //   }));
  //   setShowPayCashGiftModal(true);
  // };

  // const closePayCashGiftModal = () => {
  //   setShowPayCashGiftModal(false);
  //   setPayAmount({
  //     payCash: amount.toFixed(2),
  //     payGift: "",
  //     totalOfferAmount: amount.toFixed(2),
  //     email: email,
  //     phone: phone,
  //   });
  //   setInputValue("");
  // };

  // const OpenGiftModal = () => {
  //   // Implement the payment logic here
  //   setShowGiftModal(true);
  //   console.log("Paying with cash:", payAmount.payCash);
  //   console.log("Paying with gift:", payAmount.payGift);
  // };

  // const closeGiftModal = () => {
  //   setShowGiftModal(false);
  //   setPayAmount({
  //     payCash: amount.toFixed(2),
  //     payGift: "",
  //     totalOfferAmount: amount.toFixed(2),
  //     email: email,
  //     phone: phone,
  //   });
  //   setInputValue("");
  // };

  // const keys = [
  //   ["1", "2", "3"],
  //   ["4", "5", "6"],
  //   ["7", "8", "9"],
  //   ["#", "0", "X"],
  // ];
  // const numericRegex = /^[0-9]*\.?[0-9]*$/;

  // const handleInputChange = (e: any) => {
  //   const value = e.target.value;

  //   if (numericRegex.test(value)) {
  //     setInputValue(value);
  //     updatePayAmount(value); // Update pay amounts with the new value
  //   }
  // };

  // const handleKeyPress = (key: any) => {
  //   if (key === "X") {
  //     const updatedValue = inputValue.slice(0, -1);
  //     setInputValue(updatedValue);

  //     updatePayAmount(updatedValue);
  //     return;
  //   }

    // Validate the key with the regex (allow digits and decimal)
    // if (!numericRegex.test(inputValue + key)) {
    //   return;
    // }

  //   const updatedInputValue = inputValue + key; // Append the key to inputValue
  //   setInputValue(updatedInputValue);

  //   updatePayAmount(updatedInputValue); 
  // };

  // Function to update payGift and payCash based on input value
  // const updatePayAmount = (updatedInputValue: any) => {
  //   let numericValue = parseFloat(updatedInputValue) || 0;

  //   // Prevent negative values
  //   if (numericValue < 0) {
  //     return;
  //   }

  //   // Prevent values greater than the total offer amount
  //   if (numericValue > parseFloat(payAmount.totalOfferAmount)) {
  //     numericValue = parseFloat(payAmount.totalOfferAmount);
  //   }

  //   // Update state for valid input
  //   setPayAmount((prev) => ({
  //     ...prev,
  //     payGift: numericValue.toString(),

  //     payCash:
  //       numericValue > parseFloat(prev.totalOfferAmount)
  //         ? "0"
  //         : (parseFloat(prev.totalOfferAmount) - numericValue).toFixed(2),
  //   }));
  // };

  // useEffect(() => {
  //   setIsCheckedEmail(!!payAmount.email);
  //   // Phone is always checked
  // }, [payAmount.email]);


  // useEffect(() => {
  //   // Reset the error message if no validation errors
  //   setError("");

  //   if (Number(inputValue) > amount) {
  //     setError("Please enter valid Trade Amount.");
  //   }
  // }, [isCheckedEmail, inputValue]);


  // const handleSendAndPay = async () => {
  //   if (Error) {
  //     return;
  //   }

  //   setLoading(true);

  //   try {
  //     const response = await axios.patch(
  //       `${
  //         import.meta.env.VITE_API_BASE_URL
  //       }/kiosk/update-purchase/${purchaseOrderId}`,
  //       {
  //         cashAmount: payAmount.payCash,
  //         tradeAmount: payAmount.payGift,
  //         sendMail: false,
  //         sendSMS: false,
  //         email: payAmount.email,
  //         phoneNumber: payAmount.phone,
  //       }
  //     );

  //     const { remainingAmt } = response.data.data;
  //     const bills = response.data.availableBills.bills;

  //     // Proceed with payment if validation passes
  //     await onProcessTransactionPromise(remainingAmt, bills);

  //     setLoading(false); // Stop loading after success
  //   } catch (error) {
  //     setError("An error occurred while processing the request.");
  //     setLoading(false); // Stop loading if there's an error
  //   }
  // };

  // useEffect(() => {
  //   setPayAmount((prevState) => ({
  //     ...prevState,
  //     email: email,
  //     phone: phone,
  //   }));
  // }, [email, phone]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  //Send Gift Component
  // const SendGiftComponent = (
  //   <>
  //     <div className="modal-overlay">
  //       <div className="main-modal-wrapper">
  //         <div className="main-modal">
  //           <div className="modal-container">
  //             <div>
  //               <div className="px-4 pt-4 pb-1 flex justify-between">
  //                 <p className="text-black font-inter-font text-[27px] font-[600]">
  //                   Send Gift Card
  //                 </p>
  //               </div>
  //               <hr />
  //               <div className="px-6 pt-4 mb-6 relative">
  //                 <span className="absolute inset-y-0 left-0 flex items-center pl-10 pt-4">
  //                   <img
  //                     src="./assets/icons/sms.png"
  //                     alt="email-icon"
  //                     className="h-12 w-12"
  //                   />
  //                 </span>
  //                 <input
  //                   type="email"
  //                   placeholder="Email"
  //                   value={payAmount?.email || ""}
  //                   disabled
  //                   readOnly
  //                   className="outline-none pl-20 px-3 py-4 w-full text-gray-600 text-3xl font-[400] rounded-full border border-[#252F4A]"
  //                 />
  //               </div>
  //               <div className="px-6 pt-4 mb-6 relative">
  //                 <span className="absolute inset-y-0 left-0 flex items-center pl-10 pt-4">
  //                   <img
  //                     src="./assets/icons/cellphone.png"
  //                     alt="email-icon"
  //                     className="h-12 w-12"
  //                   />
  //                 </span>
  //                 <input
  //                   type="number"
  //                   placeholder="Phone"
  //                   value={payAmount?.phone || ""}
  //                   onChange={(e) => {
  //                     const digits = e.target.value.slice(0, 10);
  //                     setPayAmount({ ...payAmount, phone: digits });
  //                   }}
  //                   disabled={phone?.length === 10}
  //                   className="outline-none pl-20 px-3 py-4 w-full text-gray-600 text-3xl font-[400] rounded-full border border-[#252F4A]"
  //                 />
  //               </div>
  //               <hr />
  //               <div className="m-5 items-center flex p-1 justify-between">
  //                 <button
  //                   className="rounded-[66px] bg-[#F3F3F3] h-[89px] w-[430px] text-[#252F4A] font-inter-font text-[32px] font-[600]"
  //                   onClick={closeGiftModal}
  //                 >
  //                   Back
  //                 </button>
  //                 <button
  //                   className="rounded-[66px] bg-[#64BB46] h-[89px] w-[430px] text-[#ffffff] font-inter-font text-[32px] font-[600]"
  //                   onClick={handleSendAndPay}
  //                   disabled={loading}
  //                 >
  //                   {loading ? "Processing..." : "Send & Pay"}
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  //Pay Cag and Gift Component
  // const PayCashGiftComponent = (
  //   <>
  //     <div className="modal-overlay">
  //       <div className="main-modal-wrapper">
  //         <div className="main-modal">
  //           <div className="modal-container">
  //             <div>
  //               <div className="px-4 pt-4 pb-1 flex justify-between">
  //                 <p className="text-black font-inter-font text-[27px] font-[600]">
  //                   Payment Methods
  //                 </p>
  //                 <div
  //                   onClick={closePayCashGiftModal}
  //                   className="cursor-pointer text-[#FF0404] font-inter-font text-[28px] font-[600]"
  //                 >
  //                   EXIT
  //                 </div>
  //               </div>

  //               <hr />
  //               <div className="flex my-5">
  //                 <div className="bg-[#F3F3F3] text-[#252F4A] w-[450px] p-[20px] m-2 rounded-xl text-center">
  //                   <p className="text-3xl p-4">Cash Amount</p>
  //                   <p className="text-6xl font-extrabold">
  //                     {" "}
  //                     ${payAmount.payCash}
  //                   </p>
  //                 </div>
  //                 <div className="bg-[#F3F3F3] text-[#252F4A] w-[450px] p-[20px] m-2 rounded-xl text-center">
  //                   <p className="text-3xl p-4">Enter Trade Amount</p>
  //                   <div className="flex">
  //                     <span className="bg-gray-200 text-gray-600 px-3 py-2 text-4xl font-bold rounded-l-lg">
  //                       $
  //                     </span>
  //                     <input
  //                       placeholder="0.00"
  //                       id="Trade"
  //                       autoComplete="off"
  //                       value={inputValue}
  //                       onChange={handleInputChange}
  //                       className="outline-none px-3 py-2 w-full text-gray-600 text-4xl font-bold rounded-r-lg"
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="px-6 pt-4 pb-2 flex justify-between">
  //                 <p className="text-[#252F4A] font-inter-font text-[32px] font-[400]">
  //                   Total Amount
  //                 </p>
  //                 <div className="text-[#64BB46] font-inter-font text-[40px] font-[900]">
  //                   ${payAmount.totalOfferAmount}
  //                 </div>
  //               </div>
  //               <hr />
  //               {Error && (
  //                 <div className="text-red-500 font-bold text-2xl mb-4 px-6 mt-4">
  //                   <p>{Error}</p>
  //                 </div>
  //               )}
  //               <div className="m-4">
  //                 {/* keyboard */}
  //                 <div className="flex flex-col items-center">
  //                   {/* Map through each row */}
  //                   {keys.map((row, rowIndex) => (
  //                     <div key={rowIndex} className="flex space-x-4 my-2">
  //                       {row.map((key) => (
  //                         <button
  //                           key={key}
  //                           className="w-16 h-16 border-[3px] p-[50px] border-gray-400 rounded-full text-[#9A9999] text-[35px] font-[700] flex items-center justify-center"
  //                           onClick={() => handleKeyPress(key)}
  //                         >
  //                           {key}
  //                         </button>
  //                       ))}
  //                     </div>
  //                   ))}
  //                 </div>
  //               </div>
  //               <hr />
  //               <div className="m-6 items-center">
  //                 <button
  //                   className={`rounded-[66px] bg-[#64BB46] h-[89px] w-[880px] text-[#ffffff] font-inter-font text-[32px] font-[600]  disabled:bg-[#92cb82] disabled:cursor-not-allowed`}
  //                   disabled={
  //                     !(
  //                       Number(payAmount.payCash) + Number(inputValue) ===
  //                       amount
  //                     )
  //                   }
  //                   onClick={() =>
  //                     Number(payAmount.payGift) > 0
  //                       ? OpenGiftModal()
  //                       : onProcessTransactionPromise(amount, directBills)
  //                   }
  //                 >
  //                   Pay
  //                 </button>
  //                 {showGiftModal && (
  //                   <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
  //                     <div className="bg-white p-4 rounded shadow-lg">
  //                       {SendGiftComponent}
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div className="main-wrape">
        <div className="main-inner-wrape">
          <img
            src="/assets/icons/moneyBoy.png"
            className="absolute h-[600px] w-[605px] bottom-[0px] left-[185px] right-0 top-0"
            alt="moneyBoy"
          ></img>
          <div className="absolute left-[200px] right-0 bottom-[0px] top-[640px]">
            <div className="gap-4 flex text-center">
              <div className="font-inter-font text-[60px] font-[500] text-black leading-8">
                Hey
              </div>
              <div className="font-inter-font text-[60px] font-[500] text-[#64BB46] leading-8">
                {purchaseDetails?.customerName}!
              </div>
            </div>

            <div className="absolute top-20 font-inter-font text-[46px] font-[500] left-0 text-black leading-8">
              Time to get paid!
            </div>
          </div>
          <div className="absolute top-[730px] font-inter-font text-[140px] font-[900] left-[250px] right-0 text-[#64BB46]">
            ${purchaseDetails?.remainingAmt.toFixed(2)}
          </div>

          <div
            onClick={() => {
              openDialog();
            }}
            className="cursor-pointer flex gap-2 items-center absolute top-[920px] font-inter-font text-[30px] font-[500] left-[350px] right-0 text-[#7E8299]"
          >
            <div>Transaction Details</div>
            <img
              src="/assets/icons/downArrow.png"
              className="h-[30px] w-[30px] flex items-center"
              alt="downArrow"
            ></img>
          </div>

          <div className="">
            <div className="flex font-inter-font text-[35px] font-[400] text-white left-10 absolute bottom-[55px]">
              Is this correct?
            </div>

            <div className="gap-4 absolute left-[600px] flex bottom-[38px]">
              <button
                className="border-[3px] border-white rounded-[66px] bg-[#357E00] h-[89px] w-[174px] text-[#64BB46] font-inter-font text-[32px] font-[600]"
                // onClick={openPayCashGiftModal}
                onClick={()=> onProcessTransactionPromise()}
              >
                Yes
              </button>
              {/* {showPayCashGiftModal && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
                  <div className="bg-white rounded shadow-lg p-4">
                    {PayCashGiftComponent}
                  </div>
                </div>
              )} */}
              <Link to={ROUTES.OOPS.path}>
                <button className="border-[3px] border-white rounded-[66px] bg-[#C5C5C5] h-[89px] w-[174px] text-[#7E8299] font-inter-font text-[32px] font-[600]">
                  No
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <>
          <div className="modal-overlay">
            <div className="main-modal-wrapper">
              <div className="main-modal">
                <div className="modal-container">
                  <div>
                    <div className="px-4 pt-4 pb-1 flex justify-between">
                      <p className="text-black font-inter-font text-[27px] font-[600]">
                        Order Summary
                      </p>
                      <div
                        onClick={closeDialog}
                        className="cursor-pointer text-[#FF0404] font-inter-font text-[28px] font-[600]"
                      >
                        EXIT
                      </div>
                    </div>

                    <hr />
                    <div className="px-6 pt-4 pb-2 flex justify-between">
                      <p className="text-black font-inter-font text-[22px] font-[500]">
                        Order No
                      </p>
                      <div className="text-black font-inter-font text-[22px] font-[500]">
                        Date
                      </div>
                    </div>
                    <div className="px-6 pt-1 pb-4 flex justify-between">
                      <p className="text-black font-inter-font text-[22px] font-[300]">
                        {purchaseDetails?.transactionId}
                      </p>
                      <div className="text-black font-inter-font text-[22px] font-[300]">
                        {formatDate(purchaseDetails?.dateOfPurchase)}
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-col ">
                      <div className=" ">
                        <div className="py-2 inline-block min-w-full ">
                          <div className="overflow-hidden">
                            <table className="min-w-full">
                              <thead className="bg-white ">
                                <tr className="">
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] pl-4  py-4 text-left "
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600]  py-4 text-left"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] py-4 text-right"
                                  >
                                    Qty
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-black font-inter-font text-[22px] font-[600] pr-4 py-4 text-right"
                                  >
                                    Amount
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {purchaseDetails?.products.map((product: any, index: any) => (
                                  <tr key={index} className="">
                                    <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pl-4 pb-2 text-left">
                                      {index + 1}
                                    </td>
                                    <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 text-left">
                                      {`${product.Brand} ${product.Model}`}
                                    </td>
                                    <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 text-right">
                                      1
                                    </td>
                                    <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pb-2 pr-4 text-right">
                                      ${product.offerPrice.toFixed(2)}
                                    </td>
                                  </tr>
                                ))}

                                <tr className="border-t">
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] pl-4  text-left"></td>
                                  <td className="text-[#3F4254] font-inter-font text-[22px] font-[300] text-left"></td>
                                  <td className="text-black font-inter-font text-[22px] font-[600] py-3 text-right ">
                                    Total
                                  </td>
                                  <td className="text-black font-inter-font text-[22px] font-[600] pr-4 text-right ">
                                    $
                                    {purchaseDetails?.products
                                      .reduce(
                                        (acc: any, product: any) =>
                                          acc + product.offerPrice,
                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default TransactionBody;
