import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { useEffect } from "react";
//test comment
function App() {
  useEffect(() => {
    Sentry.setUser({
      username: Date.now().toString(),
    })
  }, [])

  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', preventDefault, { passive: false });
    
    return () => {
      document.removeEventListener('touchmove', preventDefault);
    };
  }, []);
  
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;
